import * as React from "react";
import {Helmet} from "react-helmet";
import Navigation from "../components/Navigation";
import {graphql, Link} from "gatsby";
import Logo from "../components/Logo";
import * as _ from "lodash";
import Breadcrumb from "../components/Breadcrumb";
import {getPageTitle} from "../components/Helpers/Meta";
import Fade from "../transitions/Fade";
import {createRef, useEffect, useState} from "react";
import {off, on, trigger} from "../components/Events";
import TransitionLink  from 'gatsby-plugin-transition-link'
import {isIOS} from "../components/Helpers/Device";
import Image from "../components/Image";

export const CASES_OVERVIEW_QUERY = graphql`
query ServiceOverviewQuery($id: String!) {
  content: contentfulServiceOverviewPage(
      id: { eq: $id }
    ) {
      services {
        metatitle
        slug
        imageRight {
          file {
            contentType
            url
          }
          gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
          title
        }
      }
    }
}
`;
const ServicesOverviewTemplate = ({data}) => {
    const {
        content: {
            services
        }
    } = data;


    const [servicesImgRef, setServicesImgRef] = useState([]);
    const items = _.shuffle(services.slice());

    const hidePage = () => {
        Fade.animate([
            { target: '.o-layout', type: Fade.TYPE_OPACITY },
            { target: '.c-logo', type: Fade.TYPE_OPACITY },
            { target: '.c-breadcrumbs', type: Fade.TYPE_OPACITY },
            { target: '.c-services--list', type: Fade.TYPE_OPACITY }
        ])
    };

    useEffect(() => {
        on('page:out', hidePage)

        const refs = [];
        for (let i = 0; i < services.length; i++) {
            refs.push(createRef())
        }
        setServicesImgRef(refs)

        return () => {
            off('page:out', hidePage)
        }
    }, [])

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {setTimeout(() => {setIsLoaded(true)}, 100)}, []);

    return (
        <>
            <Logo state={Logo.YELLOW} />
            <Navigation negative={true} />
            <Helmet>
                <title>{getPageTitle({title: 'Diensten'})}</title>
                <style>
                    {`body { background-color: #000000; }`}
                </style>
            </Helmet>
            <Breadcrumb text={"Diensten"} white={true} />

            <div className={`o-section o-layout o-layout--full-page u-columns-24 ${isLoaded ? 'e-active--delay' : ''}`}>
                <div className={"u-colspan-24 "}>
                    <div className={"c-services-overview--scrolltext-container u-font-family--secondary u-font-weight--black"}>
                        <div className={`c-services--animation ${isIOS() ? 'is-ios' : ''} `}>
                            <div className="marquee color-yellow">
                                <div className="marquee__inner reverse e-effect e-fade " aria-hidden="true">
                                    {_.shuffle(services).map((item, idx) => <span key={`m1.${idx}`}>{item.metatitle}</span>)}
                                </div>
                            </div>
                            <div className="marquee color-red">
                                <div className="marquee__inner reverse e-effect e-fade" aria-hidden="true">
                                    {_.shuffle(items.slice()).map((item, idx) => <span key={`m2.${idx}`}>{item.metatitle}</span>)}
                                    {_.shuffle(items.slice()).map((item, idx) => <span key={`m2.2.${idx}`}>{item.metatitle}</span>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"o-section c-services--bg-image o-layout--full-page"}>
                {services.map((item, index) =>
                    <Image image={item.imageRight} key={`service_1_${index}_img`} ref={servicesImgRef[index]}/>)}
            </div>

            <div className={`c-services--list ${isLoaded ? 'e-active--delay' : ''}`}>
                <ul className={"e-effect e-fade e-move--down-gentle"}>
                    {services.map((item, index) =>
                        <li key={`service_row_0_${index}`}
                            className={"c-services-overview__link"}
                            onMouseEnter={() => {
                                trigger('cursor:click');
                            }}
                            onMouseOut={() => {
                                trigger('cursor:unclick');
                            }}
                            onClick={() => {
                                trigger('cursor:unclick');
                            }}>
                            <span>
                                <TransitionLink
                                    to={`/diensten/${item.slug}`}
                                    className={"c-services-overview__link"}
                                    onMouseEnter={() => {
                                        for (let i = 0; i < servicesImgRef.length; i++) {
                                            if (servicesImgRef[i].current)
                                                servicesImgRef[i].current.classList.remove('active');
                                        }

                                        if (servicesImgRef[index].current)
                                            servicesImgRef[index].current.classList.add('active')
                                    }}
                                    onMouseOut={() => {
                                        if (servicesImgRef[index].current)
                                            servicesImgRef[index].current.classList.remove('active');
                                    }}
                                    entry={{delay: Fade.anim_delay}}
                                    exit={{
                                        length: Fade.anim_length,
                                        trigger: (details) => Fade(details)}}>{item.metatitle}</TransitionLink>

                                <span className={"c-nav-transition c-nav-transition__1"}/>
                            </span>
                        </li>)}
                </ul>
            </div>
        </>
    )
};

export default ServicesOverviewTemplate;
