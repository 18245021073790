import * as React from "react";
import {ForwardedRef} from "react";

interface ImageProps {
    image: any
}

const Image = React.forwardRef(({image} : ImageProps, ref: ForwardedRef<HTMLImageElement>) => {
    const hasImage = typeof image !== 'undefined' && typeof image.file !== 'undefined';

    if (!hasImage) return;

    const isGif = image.file.contentType === "image/gif";
    const retrievedImage = isGif
        ? image.file.url
        : image.gatsbyImageData.images.fallback.src

    return (
        <img src={retrievedImage} ref={ref} />
    )
})

export default Image;
